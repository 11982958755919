<template>
  <div class="text-caption flex-nowrap" style="min-width: 1700px;">
    <v-sheet
      outlined
      class="mt-3"
      color="grey lighten-4"
      @click="toggleState"
      style="cursor: pointer; min-width: 1600px;"
      @mouseover="upHere = true"
      @mouseleave="upHere = false"

    >
      <v-row class="pa-3 pl-4 align-center flex-nowrap" style="max-width: 1600px;">
        <v-col cols="2">
          <v-icon x-small class="mr-2" :color="statusColor"
            >mdi-checkbox-blank-circle</v-icon
          >
          {{ accountData.id }} |
          {{ accountData.name }}
          <div class="ml-6">
            {{ accountData.balance === "-" ? 0 : accountData.balance }} UAH
            <v-btn
              x-small
              icon
              class="ml-3"
              :to="{
                name: 'AccountTransactionsPage',
                params: accountData,
              }"
              ><v-icon>mdi-credit-card-edit-outline</v-icon></v-btn
            >
            <v-btn
                x-small
                icon
                class="ml-3"
                :to="{
                name: 'AccountPackagesPage',
                params: accountData,
              }"
            ><v-icon>mdi-package-variant-plus</v-icon></v-btn
            >
          </div>
        </v-col>
        <v-col cols="1" style="max-width: 100px;">{{ accountData.userQty }}</v-col>
        <v-col cols="1" style="max-width: 100px;">
          <router-link
              :to="{
                name: 'ProjectsListPage',
                query: { account: accountData.email },
              }"
          >
            {{ accountData.projectQty }}
          </router-link>
        </v-col>
        <v-col cols="1">{{ accountData.adminName }}</v-col>
        <v-col cols="2">{{ accountData.email }}</v-col>
        <v-col cols="2" style="max-width: 150px">{{ accountData.createdAt }}</v-col>
        <v-col cols="1" style="max-width: 100px;">{{ accountData.currentPackage }}</v-col>
        <v-col cols="1" style="max-width: 100px;">
            {{ accountData.nextPackage === "-" ? "" : accountData.nextPackage }}
        </v-col>
        <v-col cols="2" style="max-width: 150px">{{ accountData.lastPayment }}</v-col>
        <v-col cols="1" style="max-width: 50px">{{ accountData.amount }}</v-col>
        <v-col cols="2" class="text-end pr-4">
          <v-row>
            <v-col v-if="upHere">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on"
                    :disabled="!accountData.analyticsAllowed"
                    x-small
                    icon
                    class="mr-4 mt-1"
                    :color="dashboardColor(accountData)"
                    @click.stop="confirmDashboard"
                >
                <v-icon class="text-end">mdi-chart-bar</v-icon>
              </v-btn>
                </template>
                <p class="my-2">Dashboard access</p>
              </v-tooltip>
            </v-col>
            <v-col v-if="!upHere">
              {{ accountData.lastLogin === "-" ? "" : accountData.lastLogin }}
            </v-col>

          </v-row>
        </v-col>
      </v-row>
    </v-sheet>
    <v-progress-linear
      v-if="dataLoading"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <div v-if="state">
      <user-item
        v-for="(user, i) in users"
        :key="i"
        :user="user"
        @editInfo="editItem(user)"
        @deleteItem="confirmItem(user)"
        @resetPassword="confirmReset(user)"
        @superAccess="confirmAccess(user)"
      ></user-item>
    </div>
    <v-dialog v-if="selectedItem" v-model="deleteDialog" max-width="450px">
      <v-card>
        <v-card-title
          class="headline primary lighten-1 white--text justify-space-between py-1"
        >
          Delete confirmation
          <v-btn icon color="white" @click="deleteDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-progress-linear
          v-if="dataLoading"
          indeterminate
          color="primary"
        ></v-progress-linear>
        <v-card-text class="mt-6">
          Are you sure you want to delete {{ selectedItem.name }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="deleteDialog = false">Close</v-btn>
          <v-btn color="primary" text @click="deleteItem(selectedItem)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="selectedItem" v-model="superaccessDialog" max-width="450px">
      <v-card>
        <v-card-title
          class="headline primary lighten-1 white--text justify-space-between py-1"
        >
          Super access confirmation
          <v-btn icon color="white" @click="superaccessDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-progress-linear
          v-if="dataLoading"
          indeterminate
          color="primary"
        ></v-progress-linear>
        <v-card-text v-if="selectedItem.accountEmulationAllowed" class="mt-6">
          Are you sure you want to remove super access from
          {{ selectedItem.name }}?
        </v-card-text>
        <v-card-text v-else class="mt-6">
          Are you sure you want to allow super access for
          {{ selectedItem.name }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="superaccessDialog = false"
            >Close</v-btn
          >
          <v-btn color="primary" text @click="toggleSuperaccess(selectedItem)">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dashboardAccessDialog" max-width="450px">
      <v-card>
        <v-card-title
            class="headline primary lighten-1 white--text justify-space-between py-1"
        >
          Access to the dashboard page
          <v-btn icon color="white" @click="dashboardAccessDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-progress-linear
            v-if="dataLoading"
            indeterminate
            color="primary"
        ></v-progress-linear>
        <v-card-text v-if="accountData.dashboardAllowed" class="mt-6">
          Block access to the dashboard page for
          {{ accountData.name }}?
        </v-card-text>
        <v-card-text v-else class="mt-6">
          Are you sure to give access to the dashboard page to
          {{ accountData.name }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="dashboardAccessDialog = false"
          >Close</v-btn
          >
          <v-btn color="primary" text @click="toggleDashboardAccess(accountData)">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="selectedItem" v-model="resetDialog" max-width="450px">
      <v-card>
        <v-card-title
          class="headline primary lighten-1 white--text justify-space-between py-1"
        >
          Reset password confirmation
          <v-btn icon color="white" @click="resetDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-progress-linear
          v-if="dataLoading"
          indeterminate
          color="primary"
        ></v-progress-linear>
        <v-card-text class="mt-6">
          Are you sure to reset user password for {{ selectedItem.name }}?<br />
          The email with a link to reset password will be sent to
          {{ selectedItem.email }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="resetDialog = false">Close</v-btn>
          <v-btn color="primary" text @click="resetPassword(selectedItem)">
            Reset
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDialog" max-width="450px">
      <user-form
        v-if="editDialog"
        :user="selectedItem"
        @close="closeEditDialog"
        @submitted="
          fetchUsers();
          closeEditDialog();
        "
      ></user-form>
    </v-dialog>
  </div>
</template>

<script>
import {
  FETCH_ACCOUNT_USERS,
  FETCH_ACCOUNT,
  DELETE_USER,
  RESET_USER_PASSWORD,
  EDIT_USER, EDIT_ACCOUNT,
} from "@/store/modules/account/actions";

import UserItem from "@/views/account/list/UserItem";
import UserForm from "@/views/account/item/UserForm";
import acl from "@/mixin/acl";
import { RESOURCE_USERS } from "@/store/modules/admin/getters";
export default {
  name: "AccountItem",
  mixins: [acl],
  props: {
    account: Object,
  },
  components: { UserItem, UserForm },
  data() {
    return {
      state: false,
      upHere: false,
      usersLoaded: false,
      users: [],
      accountInfo: null,
      resetDialog: false,
      deleteDialog: false,
      superaccessDialog: false,
      dashboardAccessDialog: false,
      selectedItem: null,
      dataLoading: false,
      editDialog: false,
    };
  },
  computed: {
    canSeeUsers: function () {
      return this.hasAccess([RESOURCE_USERS]);
    },
    accountData() {
      return this.accountInfo ? this.accountInfo : this.account;
    },
    statusColor: function () {
      let color = "grey lighten-1";
      if (this.accountData.lastLogin !== "-") {
        color = this.accountData.isActive ? "green darken-1" : "red darken-4";
      }
      return color;
    },
    emulatorColor: function () {
      return "grey lighten-1";
    },
  },
  watch: {
    account: {
      deep: true,
      handler: function () {
        this.users = [];
        this.state = false;
        this.usersLoaded = false;
      },
    },
  },
  methods: {
    dashboardColor: function (accountData) {
      return accountData.dashboardAllowed
          ? "green darken-1"
          : "gray darken-1";
    },
    fetchAccount() {
      this.isLoading = true;
      this.$store
        .dispatch(`account/${FETCH_ACCOUNT}`, {
          accountId: this.account.id,
        })
        .then(
          (response) => {
            const { data } = response;
            this.accountInfo = data;
            this.isLoading = false;
          },
          (error) => {
            console.log(error);
          }
        );
    },
    redirect: function (url) {
      window.open(url, "_blank").focus();
    },
    toggleState() {
      this.state = !this.state;
      if (this.state && !this.usersLoaded) {
        this.fetchUsers();
      }
    },
    fetchUsers() {
      if (!this.canSeeUsers) {
        return false;
      }
      this.dataLoading = true;
      this.$store
        .dispatch(`account/${FETCH_ACCOUNT_USERS}`, {
          accountId: this.account.id,
        })
        .then(
          (response) => {
            console.log(response);
            this.users = response.data;
            this.usersLoaded = true;

            this.dataLoading = false;
          },
          (error) => {
            console.log(error);
          }
        );
    },
    editItem: function (item) {
      this.selectedItem = item;
      this.editDialog = true;
    },
    closeEditDialog: function () {
      this.selectedItem = null;
      this.editDialog = false;
    },
    confirmItem: function (item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    deleteItem: function (item) {
      this.dataLoading = true;
      this.$store
        .dispatch("account/" + DELETE_USER, item)
        .then(
          () => {
            this.dataLoading = false;
            this.selectedItem = null;
            this.deleteDialog = false;
            this.fetchAccount();
            this.fetchUsers();
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    confirmReset: function (item) {
      this.selectedItem = item;
      this.resetDialog = true;
    },
    resetPassword: function (item) {
      this.dataLoading = true;
      this.$store
        .dispatch("account/" + RESET_USER_PASSWORD, item)
        .then(
          () => {
            this.dataLoading = false;
            this.selectedItem = null;
            this.resetDialog = false;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    confirmAccess: function (item) {
      this.selectedItem = item;
      this.superaccessDialog = true;
    },
    confirmDashboard: function (item) {
      this.selectedItem = item;
      this.dashboardAccessDialog = true;
    },

    toggleSuperaccess: function (item) {
      this.dataLoading = true;
      this.$store
        .dispatch("account/" + EDIT_USER, {
          user: {
            accountEmulationAllowed: !item.accountEmulationAllowed,
          },
          id: item.id,
        })
        .then(
          () => {
            this.dataLoading = false;
            this.selectedItem = null;
            this.resetDialog = false;
            this.fetchUsers();
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    toggleDashboardAccess: function (item) {
      this.dataLoading = true;
      this.$store
          .dispatch("account/" + EDIT_ACCOUNT, {
            account: {
              dashboardAllowed: !item.dashboardAllowed,
            },
            id: item.id,
          })
          .then(
              () => {
                this.dataLoading = false;
                this.resetDialog = false;
                this.dashboardAccessDialog = false;
                this.fetchAccount();
              },
              (err) => {
                console.log("Err", err);
              }
          )
          .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.col {
  padding: 5px;
}
</style>
